@import 'fonts';
@import 'co-variables';
@import 'utilities';
@import 'bootstrap-overwrite';
@import 'primeng-overwrite';
@import 'primeicons/primeicons.css';

body {
    color: $co-body-color;
}

/* ==============================================================
Fonts
===============================================================*/
.helvetica-light {
    font-family: 'Helvetica Neue LT W05 45 Light', Helvetica, Arial, sans-serif;
}

.helvetica-roman {
    font-family: 'Helvetica Neue LT W05 55 Roman', Helvetica, Arial, sans-serif;
}

.helvetica-medium {
    font-family: 'Helvetica Neue LT W05 65 Medium', Helvetica, Arial, sans-serif;
}

.helvetica-bold {
    font-family: 'Helvetica Neue LT W05_75 Bold', Helvetica, Arial, sans-serif;
}

/* ==============================================================

===============================================================*/
* {
    outline: none;

    &:focus {
        outline: none;
    }
}

label {
    margin-bottom: 0;
}

/* ==============================================================
Helpers class
===============================================================*/
.base-width-height {
    width: $co-base-width-height;
    height: $co-base-width-height;
}

.base-height {
    height: $co-base-width-height;
}

.hover-bg-darken {
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba($black, 0.1);
    }
}

.hover-primary {
    &:hover {
        background-color: $co-primary;
        color: #ffffff;
    }
}

/* ==============================================================
Scrollbar
===============================================================*/
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

//Track
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 0;
    border-radius: 0;
}

//Handle
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.2);
}

/* ==============================================================
App Main Content
===============================================================*/
.content-height {
    height: calc(100vh - 60px);
}

/* ==============================================================
Control Panel
===============================================================*/
.list-projects {
    background-color: #f8f8f8;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 10;
}

//Search component
.co-search {
    .co-input-search {
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0 3rem 0.25rem 1.75rem;
        transition: all 0.3s ease 0s;
    }

    ::placeholder {
        color: rgba(84, 84, 84, 0.3) !important;
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: rgba(84, 84, 84, 0.3) !important;
    }

    ::-ms-input-placeholder {
        color: rgba(84, 84, 84, 0.3) !important;
    }

    .search-elements {
        bottom: 0.375rem;
        z-index: 1;
    }

    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .start {
        inset-inline-start: 0;
    }

    .end {
        inset-inline-end: 0;
    }
}

// List item
.co-list-item {
    position: relative;
    transition:
        background-color,
        opacity 0.3s ease;

    .hover-actions-right {
        right: 0.375rem;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 300ms;
    }

    .percent-wrapper {
        width: 4.75rem;
        height: 4.75rem;
    }

    .co-list-item-inside {
        border-bottom: 1px solid #e7e7e7;
    }
}

.co-list-item-left {
    position: relative;
    transition:
        background-color,
        opacity 0.3s ease;

    .hover-actions-left {
        left: 0.375rem;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 300ms;
    }

    .percent-wrapper {
        width: 4.75rem;
        height: 4.75rem;
    }

    .co-list-item-inside {
        border-bottom: 1px solid #e7e7e7;
    }
}

.co-list-item:not(.no-hover) {
    &:hover {
        &[data-name='Positions'][data-mapping='true'] {
            background-color: transparent;
        }

        background-color: #f5f5f5;

        .co-list-item-inside {
            border-bottom: 1px solid #f5f5f5;
        }

        &:after {
            content: '';
            border-bottom: 1px solid #f5f5f5;
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
        }

        .hover-actions-right {
            opacity: 1;
        }

        .hover-actions-left {
            opacity: 1;
        }

        .hover-hide-labels-right {
            opacity: 0;
        }
    }
}

.co-list-item-left:not(.no-hover) {
    &:hover {
        &[data-name='Positions'][data-mapping='true'] {
            background-color: transparent;
        }

        background-color: #f5f5f5;

        .co-list-item-inside {
            border-bottom: 1px solid #f5f5f5;
        }

        &:after {
            content: '';
            border-bottom: 1px solid #f5f5f5;
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
        }

        .hover-actions-right {
            opacity: 1;
        }

        .hover-actions-left {
            opacity: 1;
        }

        .hover-hide-labels-right {
            opacity: 0;
        }
    }
}

.co-list-item.active {
    background-color: rgb(235, 235, 235, 0.7) !important;
    z-index: 1;

    .co-list-item-inside {
        border-bottom: 1px solid #ebebeb;
    }

    &:after {
        content: '';
        border-bottom: 1px solid #ebebeb;
        position: absolute;
        left: 0;
        right: 0;
        top: -1px;
    }
}

//add top-border on first list-item
.first-item.co-list-item {
    .co-list-item-inside {
        &:before {
            content: '';
            border-bottom: 1px solid #e7e7e7;
            position: absolute;
            left: 1rem;
            right: 1rem;
            top: 0;
        }
    }
}

.first-item.co-list-item:not(.no-hover) {
    .co-list-item-inside {
        &:hover:before {
            border-bottom: 1px solid #f5f5f5;
        }
    }
}

.first-item.co-list-item.active {
    .co-list-item-inside {
        &:before {
            border-bottom: 0;
        }
    }
}

.co-list-item-height {
    height: 4.75rem;
}

.co-list-header-height {
    height: 5rem;
}

.hide-actions {
    opacity: 0;
    transition: opacity 300ms ease;
}

.item:hover .hide-actions {
    opacity: 1;
}

//badge
.co-badge {
    display: inline-block;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.1rem 0.5rem;
    white-space: nowrap;
    vertical-align: baseline;
    color: $white;
    position: relative;
}

.co-badge-1 {
    background-color: #20c875;
}

.co-badge-2 {
    background-color: #909090;
}

.co-badge-3 {
    background-color: #a25ddc;
}

.co-badge-4 {
    background-color: #579bfc;
}

.co-badge-5 {
    background-color: #e2445c;
}

.co-badge-6 {
    background-color: #254896;
}

/* ==============================================================
Shadow Button Hover
===============================================================*/
.shadow-button {
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shadow-button-height {
    height: 1.75rem;
}

.shadow-button-clicked {
    background-color: #545454 !important;
}

.shadow-button-fake {
    min-width: 1.75rem;
    min-height: 1.75rem;
}

.shadow-button-hover {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 0.25rem;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba($white, 0.9);
        box-shadow: 0 1px 3px rgba($black, 0.3);
    }
}

.action-button-success {
    width: 1.75rem;
    height: 1.75rem;
    display: grid;
    place-items: center;
    background-color: $co-button-green;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: lighten($co-button-green, 0.1);
        box-shadow: 0 1px 3px rgba($black, 0.3);
    }
}

.action-button-primary {
    width: 1.75rem;
    height: 1.75rem;
    display: grid;
    place-items: center;
    background-color: #0673d4;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: lighten(#0673d4, 0.1);
        box-shadow: 0 1px 3px rgba($black, 0.3);
    }
}

/* ==============================================================
properties-panel
===============================================================*/
.properties-panel {
    position: fixed;
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    width: 37.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    background-color: $white;
    transition: right 0.4s 0.1s ease;
    z-index: 1501;
}

.properties-panel-right {
    position: fixed;
    right: auto;
    left: 0;
    top: 0;
    bottom: 0;
    width: 37.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    background-color: $white;
    transition: right 0.4s 0.1s ease;
    z-index: 1501;
}

.properties-panel-half-width {
    width: 18.75rem !important;
}

.properties-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    padding: 1rem;
    background-color: $white;
    z-index: 10;
}

.dropdown-menu {
    margin-top: -0.75rem;
    box-shadow: 0 5px 15px rgba($black, 0.2);
    border: 1px solid #ededed;
    border-radius: 4px;
}

/* ==========================================================================
helpers
========================================================================== */
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.link {
    color: $co-primary;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.table-layout-fixed {
    table-layout: fixed !important;

    table {
        table-layout: fixed !important;
    }
}

//remove caret icon from ngbDropdown
.dropdown-toggle-none.dropdown-toggle::after {
    display: none;
}

html {
    height: 100%;
    width: 100%;
}

.filter-item {
    padding: 0.3125rem 1rem;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #f5f5f5;
    }
}

.filter-item-rtl {
    padding: 0.3125rem 1rem;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #f5f5f5;
    }
}

// override ngBootstrap dropdown
.dropdown-item {
    padding: 0.3125rem 1rem;
}

/* ==========================================================================
Layout
========================================================================== */
.co-panel-search {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    ::placeholder {
        opacity: 1;
    }

    .ic-search {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 10px 0 0;
    }

    .input-search {
        flex-grow: 1;
        position: relative;

        > input {
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $co-border-light;
            border-radius: 0 !important;
            font-weight: 300;
            padding: 5px 45px 5px 0;
            line-height: 1.5;
            transition: all 0.3s ease 0s;

            &:hover {
                border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            }

            &:focus {
                border-bottom: 1px solid rgba(0, 0, 0, 1);
            }
        }

        .ic-clear-search {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            padding: 5px;
            background: transparent;
            border: none;
        }
    }
}

.co-panel-list {
    .p-listbox {
        border: 0;
    }

    .p-listbox-list {
        .user-list {
            padding: 6px 15px;
            cursor: pointer;
            transition: all 0.25s ease-in-out;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        li.active {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}

.co-route-title {
    height: 50px;
    border-bottom: 1px solid $co-border-light;

    h4 {
        font-size: 22px;
        margin: 0;
    }
}

.button-40 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.25s ease-out;

    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.button-40:disabled {
    opacity: 0.2;
    cursor: not-allowed;
}

.btn-not-needed {
    color: #25252c80;
    background-color: #fff;
    border-color: #25252c80;

    &:hover {
        background-color: #f5f5f5;
    }
}

/* ==========================================================================
Colored ngbTooltip classes
========================================================================== */

body .tooltip-accepted {
    .tooltip-inner {
        background-color: #049d0b !important;
    }

    .arrow:before {
        border-top-color: #049d0b !important;
    }
}

body .tooltip-pending {
    .tooltip-inner {
        background-color: #0c64b3 !important;
    }

    .arrow:before {
        border-top-color: #0c64b3 !important;
    }
}

body .tooltip-rejected {
    .tooltip-inner {
        background-color: #911919 !important;
    }

    .arrow:before {
        border-top-color: #911919 !important;
    }
}

/* ==========================================================================
Dot that signalises project/location has rejected document
========================================================================== */
.rejected-dot {
    background-color: #e2445c;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

/* ==========================================================================
Dot that signalises updated stat
========================================================================== */
.badge-dot {
    &::after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        background: #e2445c;
        border-radius: 50%;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
        border: 2px solid $white;
    }
}

.btn-modal {
    min-width: 7.5rem;
    font-size: 1rem;
}

.btn.disabled,
.btn:disabled {
    opacity: 0.3;
}

@media screen and (max-width: 480px) {
    .login-footer {
        position: relative !important;
    }
    .logo-login {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
}

.expand-collapse-link {
    color: #0673d4;
    user-select: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

/* Spinner during download in time-validation and reports */
.time-validation-spinner {
    display: flex;
    justify-content: center;
    width: 80px;
    height: 40px;

    .ui-progress-spinner {
        height: 40px;
        width: 40px;
    }
}

.p-calendar-icon {
    position: relative;

    &:after {
        position: absolute;
        height: 100%;
        inset-inline-end: 0.5rem;
        top: 0;
        display: flex;
        align-items: center;
        content: url('/content/assets/icons/ico-calendar.svg');
    }
}

/* Used for positioning new calendar icon on p-calendar component */
.ico-calendar {
    position: absolute;
    height: 100%;
    right: 0.5rem;
    top: 0;
    display: flex;
    align-items: center;
}

.ico-calendar-right {
    position: absolute;
    height: 100%;
    left: 0.5rem;
    top: 0;
    display: flex;
    align-items: center;
}

/* Used for styling p-calendar */
.calendar-style {
    border-radius: 0;

    &:disabled {
        background-color: rgba(239, 239, 239, 0.3);
        opacity: 1 !important;
    }
}

/* Dropdown on hover */
.hover-dropdown-menu {
    position: absolute;
    min-width: 12.5rem;
    background-color: #ffffff;
    border: 1px solid #5454541a;
    border-radius: 0.25rem;
    top: 2rem;
    right: -10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        top: -5px;
        left: 88%;
        transform: translateX(-50%);

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #ffffff transparent;
    }

    &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: 75%;
        transform: translateX(-50%);
        width: 6rem;
        height: 0.75rem;
    }
}

.hover-dropdown-menu-rtl {
    position: absolute;
    min-width: 12.5rem;
    background-color: #ffffff;
    border: 1px solid #5454541a;
    border-radius: 0.25rem;
    top: 2rem;
    left: -10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        top: -5px;
        right: 88%;
        transform: translateX(50%);

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #ffffff transparent;
    }

    &:before {
        content: '';
        position: absolute;
        top: -5px;
        right: 75%;
        transform: translateX(50%);
        width: 6rem;
        height: 0.75rem;
    }
}

/* remove max-width on ngBootstrap tooltip */
.tooltip-no-max-width {
    .tooltip-inner {
        max-width: none;
        white-space: pre;
    }
}

/* loading spinner positioning */
.spinner-container {
    top: calc(50% - 4.75rem / 2);
    height: 4.75rem !important;
    left: calc(50% - 4.75rem / 2);
}

/* used when spinner has position-sticky to prevent moving of content */
.spinner-negative-margin {
    margin-top: -4.75rem;
}

.table-users th {
    background: #ebebeb !important;
    font-weight: 400 !important;
    border-bottom: 1px solid #d5d5d5 !important;
    height: 2rem;
}

.table-users td {
    height: 50px;
    border-bottom: 1px solid #e7e7e7 !important;
}

.has-actions {
    height: 50px !important;

    .actions {
        opacity: 0;
        transition: opacity 0.1s ease;
    }

    &:hover {
        background: #f2f2f2 !important;

        .actions {
            opacity: 1;
        }
    }
}

// p-checkbox with display flex, and positioning of checkbox
.p-checkbox-flex {
    display: flex;
    margin-bottom: 3px;

    > div {
        margin-top: 3px;
        margin-right: 8px;
        margin-left: 8px;
    }
}

// new Closeout text input, textarea and dropdown styling //
.form-group {
    margin-bottom: 0;

    .form-control-label {
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }

    select.form-control {
        font-size: 0.8rem;
        padding: 0 0.5rem;
        appearance: auto;

        &:read-only {
            background-color: #fff;
        }
    }

    .form-control {
        padding: 0.4375rem 0.5rem !important;
        height: 30px;
        border-radius: 0;
        background: #ffffff;
        border: 1px solid #5454541a;
        opacity: 1;

        &::placeholder {
            color: #5454544d;
        }

        &:disabled {
            background: #5454540f 0 0 no-repeat padding-box;
        }

        &:read-only {
            background: #5454540f 0 0 no-repeat padding-box;
        }
    }

    textarea.form-control {
        min-height: 7rem;

        &::placeholder {
            color: #5454544d;
        }

        &:disabled {
            background: #5454540f 0 0 no-repeat padding-box;
            pointer-events: none;
        }

        &:read-only {
            background: #5454540f 0 0 no-repeat padding-box;
        }
    }
}

.holiday-calendar {
    padding: 0;
    height: 30px;
    width: 100%;

    .p-inputtext {
        border: 1px solid #5454541a;
    }

    ::placeholder {
        opacity: 0.3;
    }
}

/* ngbTooltip default class */
.tooltip {
    z-index: 2000 !important;
    // prevents tooltip flickering when cursor is on the edge of host element
    pointer-events: none !important;
}

// used to put nbgTooltip on top when used inside PrimeNG dropdown and both are appended to body
body .tooltip.dropdown-tooltip {
    z-index: 9999 !important;
}

/* new table styling */
.co-table {
    th,
    td {
        padding: 0.25em 1.5em;
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}

/* chevron buttons */
.btn-nav-prev,
.btn-nav-next {
    background: rgba(255, 255, 255, 1) 0 0 no-repeat padding-box;
    border: 1px solid rgba(84, 84, 84, 0.1);
    opacity: 1;

    &:focus {
        box-shadow: none;
    }
}

.btn-nav-prev {
    border-right: 0;
    border-radius: 0.25rem 0 0 0.25rem;
}

.btn-nav-next {
    border-radius: 0 0.25rem 0.25rem 0;
}

/* vertical divider */
.v-divider {
    height: 1.5rem;
    width: 2px;
    background: #54545424;
}

// widget drag area class
.co-grid-stack-handle {
    cursor: grab;
}

.custom-padding-right {
    padding-right: 5px !important;
}

.custom-padding-left {
    padding-left: 5px !important;
}

.dropdown-item:active {
    color: #212529;
}

/* ==============================================================
Left side filters
===============================================================*/
.left-filter {
    background-color: #eee;
    position: absolute;
    top: 0;
    left: -200px;
    width: 200px;
    z-index: 11;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    height: calc(100vh - 60px);
    transition: transform 0.3s ease;
}

.left-filter.parent-filter {
    top: 60px;
    width: 300px;
    left: -300px;
    transition: none;
    background-color: #f5f5f5;
    box-shadow: none;
}

.toggle-filter {
    transform: translate3d(200px, 0, 0);
}

.toggle-filter.parent-filter {
    transform: translate3d(300px, 0, 0);
}

.pl-transition {
    transition: padding-left 0.3s ease;
}

.pl-200 {
    padding-left: 200px;
}

.ps-200 {
    padding-inline-start: 200px;
}

.ps-transition {
    transition: padding-inline-start 0.3s ease;
}

/* ==============================================================
Right side filters
===============================================================*/

.pr-200 {
    padding-right: 200px;
}

.pr-transition {
    transition: padding-right 0.3s ease !important;
}

.right-filter {
    background-color: #eee;
    position: absolute;
    top: 0;
    right: -200px;
    width: 200px;
    z-index: 11;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    height: calc(100vh - 60px);
    transition: transform 0.3s ease;
}

.right-filter.parent-filter {
    top: 60px;
    width: 300px;
    right: -300px;
    transition: none;
    background-color: #f5f5f5;
    box-shadow: none;
}

.toggle-filter-right {
    transform: translate3d(-200px, 0, 0) !important;
}

.toggle-filter-right.parent-filter {
    transform: translate3d(-300px, 0, 0) !important;
}

// login image background
.bg-login {
    background:
        linear-gradient(0deg, rgba(53, 53, 63, 0.75), rgba(53, 53, 63, 0.75)),
        url('/content/images/bg-login.webp') no-repeat center center fixed;
    background-size: cover;
}

// Gridstack.js widget item
.grid-stack-item-content {
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 4px;
}

// counter for notifications/chat
.notification-count {
    position: absolute;
    width: 16px;
    height: 16px;
    background: red;
    border-radius: 16px;
    color: white;
    top: 12px;
    right: 12px;
    font-size: 0.7em;
    text-align: center;
    opacity: 1 !important;
}

//remove first element marked dark gray
.p-dropdown-panel {
    .p-dropdown-items {
        .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
            background: none !important;

            &:hover {
                background-color: #f5f5f5 !important;
            }
        }
    }
}

//remove first element marked dark gray
.p-multiselect-panel {
    .p-multiselect-items {
        .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
            background: none !important;

            &:hover {
                background-color: #f5f5f5 !important;
            }
        }
    }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: #007bff;
}

//set text to be white when background is blue (selected)
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: white;
}

.header-input {
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 0.875rem;
    padding-top: 0.5rem;
    padding-inline-start: 1.5rem;
    padding-inline-end: 0.5rem;
    padding-bottom: calc(0.25rem + 1px);

    &::placeholder {
        color: rgba(84, 84, 84, 0.3) !important;
        opacity: 1;
    }
}

@media only screen and (max-width: 1280px) and (min-width: 768px) and (max-height: 800px) and (min-height: 400px) {
    .form-group {
        margin-bottom: 0;

        .form-control-label {
            font-size: 0.75rem; /* Smaller font size */
        }

        select.form-control {
            font-size: 0.4rem; /* Smaller font size */
            //padding: 0 0.4rem; /* Adjusted padding */
            appearance: auto;

            &:read-only {
                background-color: #fff;
            }
        }

        .form-control {
            padding: 0px !important; /* Adjusted padding */
            height: 20px; /* Reduced height */
            border-radius: 0;
            background: #ffffff;
            border: 1px solid #5454541a;
            opacity: 1;

            &::placeholder {
                color: #5454544d;
            }

            &:disabled {
                background: #5454540f 0 0 no-repeat padding-box;
            }

            &:read-only {
                background: #5454540f 0 0 no-repeat padding-box;
            }
        }

        textarea.form-control {
            min-height: 3.5rem; /* Reduced minimum height */

            &::placeholder {
                color: #5454544d;
            }

            &:disabled {
                background: #5454540f 0 0 no-repeat padding-box;
                pointer-events: none;
            }

            &:read-only {
                background: #5454540f 0 0 no-repeat padding-box;
            }
        }
    }
    .co-list-item {
        position: relative;
        transition:
            background-color,
            opacity 0.3s ease;
        //overflow-x: hidden;

        .hover-actions-right {
            right: 0.375rem;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity 300ms;
        }

        .percent-wrapper {
            width: 2.75rem;
            height: 4.75rem;
        }

        .co-list-item-inside {
            border-bottom: 1px solid #e7e7e7;
        }
    }
    .toggle-filter-right {
        transform: translate3d(-200px, 0, 0) !important;
    }

    .toggle-filter-right.parent-filter {
        transform: translate3d(300px, 0, 0);
    }
    .toggle-filter {
        transform: translate3d(263px, 0, 0);
    }

    .toggle-filter.parent-filter {
        transform: translate3d(300px, 0, 0);
    }
    .left-filter {
        background-color: #eee;
        position: absolute;
        top: 0;
        left: -263px !important;
        width: 200px;
        z-index: 11;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        height: calc(100vh - 60px);
        transition: transform 0.3s ease;
    }
    .list-projects {
        background-color: #f8f8f8;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 10;
        width: 300px !important;
    }
}

.rtl {
    direction: rtl;

    ::ng-deep .p-datepicker-prev svg {
        transform: rotate(180deg) !important;
    }

    ::ng-deep .p-datepicker-next svg {
        transform: rotate(180deg) !important;
    }
}

.flex-200px {
    flex: 0 0 12.5rem;
}

.filter-lvl-1 {
    transition: margin-inline-start 300ms;

    &.hide {
        margin-inline-start: -12.5rem;
        height: 0;
    }
}
